import { useSession } from "next-auth/client";
import UnreviewOrdersRepository, {
  UnreviewOrder,
} from "repositories/UnreviewOrders";
import { SWROptions, SWRResponse } from "shared/types";
import useSWR, { SWRConfiguration } from "swr";

/**
 *
 * @param options
 * @returns
 *  - unreviewOrders: UnreviewOrder[]
 *  - isLoading: boolean
 *  - isError: boolean
 *  - mutate: () => void;
 */
export const useUnreviewOrders = (
  options?: SWROptions & SWRConfiguration
): SWRResponse<{
  unreviewOrders: UnreviewOrder[];
}> => {
  const [session] = useSession();
  const key = !session || options?.skip ? null : "/me/unreview_orders";
  const { data, error, mutate } = useSWR(key, UnreviewOrdersRepository.index, {
    fallbackData: [],
    ...options,
  });
  return {
    unreviewOrders: data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
