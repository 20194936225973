import { ReviewForm } from "features/review/components/ReviewForm";
import { UnreviewOrder } from "repositories/UnreviewOrders";
import { Avatar } from "shared/components/atoms/Avatar";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";

type ReviewModalProps = BaseModalProps &
  UnreviewOrder & {
    onSubmit?: () => void;
  };

/**
 * @param [number] id
 * @param [OrderType] type
 * @param [String] fortuneTellerImage
 * @param [String] fortuneTellerName
 * @param [() => void] onSubmit
 */
export const ReviewModal = ({
  fortuneTellerImage,
  fortuneTellerName,
  ...reviewFormProps
}: ReviewModalProps) => {
  return (
    <BaseModal>
      <div tw="flex-center flex-col">
        <Avatar src={fortuneTellerImage} size={64} />
        <h3 tw="font-base text-base tracking-[.8px] mt-3">
          {fortuneTellerName}先生に
          <br />
          鑑定のお礼を伝えよう💌
        </h3>
        <ReviewForm {...reviewFormProps} />
      </div>
    </BaseModal>
  );
};
