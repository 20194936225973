import { useFormContext } from "react-hook-form";
import StarIcon from "shared/assets/icons/star.svg";
import { IconButton } from "shared/components/atoms/IconButton";
import { css } from "styled-components";
import tw from "twin.macro";

const iconCss = css({ ":first-child": { marginLeft: 0 } });

const RateInput = ({ name, value }: { name: string; value: number }) => {
  const { register, watch, setValue, trigger } = useFormContext();

  const score = watch(name);

  const onChangeScore = (value: number) => {
    setValue(name, score === value ? score - 1 : value);
    trigger(name);
  };

  return (
    <>
      <input type="radio" value={value} tw="hidden" {...register(name)} />
      <IconButton
        icon={
          <StarIcon
            tw="svg flex-center ml-4"
            css={[iconCss, score >= value ? tw`text-theme` : tw`text-gray`]}
            width={33}
            height={30}
            viewBox="0.5,0,11,12"
          />
        }
        onClick={() => onChangeScore(value)}
      />
    </>
  );
};

export interface ReviewRatingInputProps {
  name: string;
}

export const ReviewRatingInput = ({ name }: ReviewRatingInputProps) => {
  return (
    <div tw="flex mt-4">
      {[...Array(5)].map((_, i: number) => {
        const value = i + 1;
        return <RateInput key={value} name={name} value={value} />;
      })}
    </div>
  );
};
