import { ReviewRatingInput } from "features/review/components/ReviewRatingInput";
import { useReviewForm } from "features/review/hooks/useReviewForm";
import { FormProvider } from "react-hook-form";
import { repository } from "repositories";
import { useUnreviewOrders } from "repositories/hooks/useUnreviewOrders";
import { UnreviewOrder } from "repositories/UnreviewOrders";
import { Button } from "shared/components/atoms/Button";
import { TextArea } from "shared/components/atoms/TextArea";
import { BaseModalProps } from "shared/components/organisms/modal/BaseModal";
import { Loading } from "shared/components/templates/Loading";
import useBeforeLeaveEffect from "shared/hooks/useBeforeLeaveEffect";
import {
  commonErrorMessage,
  useErrorMessage,
} from "shared/hooks/useErrorMessage";
import { useFlashContext } from "shared/hooks/useFlashContext";
import useLocalStorage from "shared/hooks/useLocalStorage";
import { Colors } from "shared/styles";
import { orderTypes } from "shared/types";
import { css } from "twin.macro";

const placeholderCss = css({
  "::placeholder": {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "20px",
    color: Colors.gray["01"],
  },
});

type ReviewModalProps = BaseModalProps &
  Omit<UnreviewOrder, "fortuneTellerName" | "fortuneTellerImage"> & {
    onSubmit?: () => void;
  };

/**
 * @param [number] id
 * @param [OrderType] type
 * @param [() => void] onSubmit
 */
export const ReviewForm = ({ id, type, onSubmit }: ReviewModalProps) => {
  const { getSerializedItem, setItemAsString, removeItem, keys } =
    useLocalStorage();
  const { form, fields, disabled } = useReviewForm({
    defaultValues: {
      content: getSerializedItem(keys.reviewContent),
    },
  });

  const { toggleFlash } = useFlashContext();
  const { handleAxiosError } = useErrorMessage();
  const { mutate } = useUnreviewOrders();
  const onSuccess = () => {
    mutate();
    removeItem(keys.reviewContent);
    toggleFlash({
      message: "レビューを投稿しました",
    });
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      if (type === orderTypes.chat) {
        await repository.fortuneReview.postReview(id, data).req();
        onSuccess();
      } else if (type === orderTypes.tel) {
        await repository.telFortuneReview.postReview(id, data).req();
        onSuccess();
      } else {
        toggleFlash({ message: commonErrorMessage });
      }
      if (onSubmit) onSubmit();
    } catch (error) {
      handleAxiosError(error);
    }
  });

  useBeforeLeaveEffect(() => {
    const content = form.getValues(fields.content.name);
    setItemAsString(keys.reviewContent, content);
  }, []);

  return (
    <>
      <Loading loading={form.formState.isSubmitting} />
      <FormProvider {...form}>
        <form tw="flex-center flex-col w-full" onSubmit={handleSubmit}>
          <ReviewRatingInput name={fields.score.name} tw="mt-4" />
          <TextArea
            tw="mt-[22px]"
            css={placeholderCss}
            name={fields.content.name}
            placeholder="例: 先生に鑑定していただいてから、どうしてか分からないんですけど、今までみたいにネガティブな感情を抱くことが減りました(><)復縁にむけて頑張ってみます♡ありがとうございました!"
            rows={10}
          />
          <Button
            tw="w-full bg-gradient-theme-to-l mt-6"
            type="submit"
            disabled={disabled}
          >
            お礼を送信する
          </Button>
        </form>
      </FormProvider>
    </>
  );
};
