import { useLayoutEffect } from "react";

const useBeforeLeaveEffect = (onBeforeUnload: () => void, deps?: any): void => {
  const watcher = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    onBeforeUnload();
  };

  useLayoutEffect(() => {
    window.addEventListener("beforeunload", watcher);
    return () => window.removeEventListener("beforeunload", watcher);
  }, [deps]);

  useLayoutEffect(() => {
    window.addEventListener("popstate", watcher);
    return () => window.removeEventListener("popstate", watcher);
  }, [deps]);
};

export default useBeforeLeaveEffect;
