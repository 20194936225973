import { ReviewModal } from "components/organisms/modal/ReviewModal";
import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import { useCallback, useRef } from "react";
import { UnreviewOrder } from "repositories/UnreviewOrders";

/**
 *
 * @returns
 *  - showReviewModal: (orderInfo: UnreviewOrder, onReview?: () => void) => void
 *  - reviewModal: MutableRefObject<ShowFnOutput<any>>;
 */
export const useReviewModal = () => {
  const { showModal } = useModal();
  const reviewModal = useRef<ShowFnOutput<any>>(null);

  const showReviewModal = useCallback(
    (orderInfo: UnreviewOrder, onReview?: () => void) => {
      if (reviewModal.current) return;

      reviewModal.current = showModal(ReviewModal, {
        ...orderInfo,
        onSubmit: () => {
          if (onReview) onReview();
          reviewModal.current.destroy();
          reviewModal.current = null;
        },
        onDismiss: () => {
          reviewModal.current.destroy();
          reviewModal.current = null;
        },
      });

      return reviewModal.current;
    },
    [showModal]
  );

  return {
    showReviewModal,
    reviewModal,
  };
};
