import clients from "shared/repositories/HttpClient";
import { OrderType } from "shared/types";

export interface UnreviewOrder {
  id: number;
  type: OrderType;
  fortuneTellerImage: string;
  fortuneTellerName: string;
}

const UnreviewOrdersRepository = {
  index: async (): Promise<UnreviewOrder[]> => {
    const { data } = await clients.miror.get("/me/unreview_orders");
    return data.unreviewOrders;
  },
};

export default UnreviewOrdersRepository;
