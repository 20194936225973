import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import * as yup from "yup";

export interface FormData {
  score: number;
  content: string;
}

const fields = {
  score: {
    name: "score",
    label: "",
  },
  content: {
    name: "content",
    label: "",
  },
} as const;

const schema = yup.object().shape({
  score: yup.number().required().min(1).max(5),
  content: yup.string().trim().label("レビュー").required(),
});

const resolver = yupResolver(schema);

interface UseReviewFormReturn {
  form: UseFormReturn<FormData>;
  fields: typeof fields;
  disabled: boolean;
}

export const useReviewForm = (
  options?: Omit<UseFormProps, "resolver">
): UseReviewFormReturn => {
  const form = useForm<FormData>({
    mode: "onChange",
    resolver,
    ...options,
  });
  const { isValid, isSubmitting } = form.formState;
  const disabled = !isValid || isSubmitting;
  return {
    form,
    fields,
    disabled,
  };
};
